import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "./Pay.module.css";

const Success = () => {
  const [secondsRemaining, setSecondsRemaining] = useState(5);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const timer = setInterval(() => {
      if (secondsRemaining > 0) {
        setSecondsRemaining((prevSeconds) => prevSeconds - 1);
      }
    }, 1000);

    // Redirige al usuario a localhost:3000/ después de 5 segundos
    const redirectTimer = setTimeout(() => {
      navigate("/");
    }, 5000);

    // Limpia los temporizadores cuando el componente se desmonta
    return () => {
      clearInterval(timer);
      clearTimeout(redirectTimer);
    };
  }, [navigate, secondsRemaining]);

  return (
    <div className={styles.container}>
      <h1 className={styles.h1}>Pago Exitoso</h1>
      <p className={styles.p}>¡Gracias por su compra!</p>
      <p className={styles.redirect}>
        Redireccionando en{" "}
        <span className={styles.secondsRemaining}>{secondsRemaining}</span>{" "}
        segundos...
      </p>
    </div>
  );
};

export default Success;