import FiltroCategoria from './FiltroCategoria'
import OrderName from '../Filtros/OrderName'
import OrderPrice from '../Filtros/OrderPrecio'
import styles from './Filtros.module.css'
import { useLocation } from 'react-router-dom'

export default function Filtros() {

  const location = useLocation()

  const ruta = location.pathname.split("/").includes('searchproduct')

  return (
    <>
      <div className={styles.divMain}>

        { ruta ? "" :
        <>
        <div className={styles.divOne}>
          <FiltroCategoria />
        </div>
        <div className={styles.query}>
          <div className={styles.divTwo}>
            <OrderName />
          </div>
          <div className={styles.divThree}>
            <OrderPrice />
          </div>
        </div>
        </>
        }
      </div>
    </>
  );
}
