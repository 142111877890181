import { GET_BANNER } from "../actionTypes";
import axios from "axios";

export const getBanner= () => {
  return async function (dispatch) {
    try {
      const response = await axios.get(`https://api.fixershoes.com/banner/`);
      const imagenes = response.data;
      dispatch({ 
        type: GET_BANNER, 
        payload: imagenes
    });
    } catch (error) {
      return "Hubo un error al traer los banners: " + error.message;
    }
  };
};