import { useForm, Controller } from "react-hook-form";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import style from "./RegistroUsuario.module.css";
import { NavLink, useNavigate } from "react-router-dom";
import { isBefore, parseISO } from "date-fns";
import axios from "axios";
import { createUser, closeUser } from "../../redux/Actions/createUser";

const RegistroUsuario = () => {
  const dispatch = useDispatch();
  const [existe, setExiste] = useState(null);
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors },
    trigger,
    reset,
    watch,
  } = useForm();
  const currentDate = new Date();
  const [departamentos, setDepartamentos] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [selectedDepartamento, setSelectedDepartamento] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);
  const [erroresPass, setErroresPass] = useState([]);
  const [isRepeatPasswordEnabled, setIsRepeatPasswordEnabled] = useState(false);
  const [validate, setValidate] = useState(false);
  const password = watch("password");
  const registro = useSelector((state) => state.registerConfirm);
  const [confirmRegistro, setConfirmRegistro] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);
  useEffect(() => {
    try {
      setConfirmRegistro(registro);
    } catch (error) {
      console.error("Error al obtener registro:", error);
    }
  }, [registro]);
  const togglePasswordVisibilityPass = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibilityPassRe = () => {
    setShowPasswordRepeat(!showPasswordRepeat);
  };
  const validatePassword = (e) => {
    const valor = e.target.value;
    const hasMinLength = valor.length >= 5;

    const errorMessages = [];
    if (valor === "") {
      errorMessages.push("Este campo es obligatorio");
    }
    if (!hasMinLength) {
      errorMessages.push("Debe tener un mínimo de 5 caracteres.");
    }

    setErroresPass(errorMessages);
    setValidate(errorMessages.length === 0);
  };

  useEffect(() => {
    const fetchDepartamentos = async () => {
      try {
        const response = await axios.get(
          "https://www.datos.gov.co/resource/xdk5-pm3f.json"
        );
        const departamentosData = response.data.map(
          (municipio) => municipio.departamento
        );
        const departamentosUnicos = [...new Set(departamentosData)];
        setDepartamentos(departamentosUnicos);
      } catch (error) {
        console.error("Error al obtener los departamentos:", error);
      }
    };
    fetchDepartamentos();
  }, []);
  const handleDepartamentoChange = async (e) => {
    const selected = e.target.value;
    setSelectedDepartamento(selected);
    try {
      const response = await axios.get(
        "https://www.datos.gov.co/resource/xdk5-pm3f.json"
      );
      const departamentosData = response.data.map(
        (municipio) => municipio.departamento
      );
      const departamentosUnicos = [...new Set(departamentosData)];
      setDepartamentos(departamentosUnicos);
      const municipiosFiltrados = response.data
        .filter((municipio) => municipio.departamento === selected)
        .map((municipio) => municipio.municipio);
      setMunicipios(municipiosFiltrados);
    } catch (error) {
      console.error("Error al obtener los departamentos:", error);
    }
  };
  const verifyEmail = async (email) => {
    try {
      const URL = "https://api.fixershoes.com/users/email";
      const query = `?email=${email}`;
      const endpoint = URL + query;
      const res = await axios.get(endpoint);

      const { success } = res.data;
      if (success === true) {
        setExiste(true);
        return true;
      } else setExiste(false);
      return false;
    } catch (error) {
      return error.message;
    }
  };

  useEffect(() => {
    if (errors.password || password === "") setIsRepeatPasswordEnabled(false);
    if (!errors.password && password !== "") setIsRepeatPasswordEnabled(true);
  }, [password, errors.password]);
  const passwordMatchRule = (value) =>
    value === password || "Las contraseñas no coinciden";
  const onSubmit = async (data) => {
    setFormDisabled(true);
    const fechaNacimiento = new Date(data.birthDate);
    const fechaActual = new Date();
    const diferenciaMilisegundos = fechaActual - fechaNacimiento;
    const edad = Math.floor(
      diferenciaMilisegundos / (365.25 * 24 * 60 * 60 * 1000)
    );
    delete data.repeatPassword;
    data.age = edad;
    dispatch(createUser(data));
    setTimeout(() => {
      reset();
      dispatch(closeUser());
      setFormDisabled(false);
      navigate("/login");
    }, 1000);
  };
  return (
    <div className={style.div}>
      <div className={style.divComplementario}>
        <img
          className={style.logo}
          src="https://res.cloudinary.com/dgxp4c4yk/image/upload/v1694710937/FIXERSHOES/LOGO-FIXER-SOLO-PNG_mwfsfe.png"
          alt="Logo"
        />
        <h1 className={style.titulo}>Crea tu cuenta</h1>
      </div>
      <div className={style.divForm}>
        <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={style.divDatos}>
            <div className={style.divCampo}>
              <label className={style.label} htmlFor="name">
                Nombre completo
              </label>
              <div className={style.divInput}>
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Este campo es obligatorio",
                    maxLength: {
                      value: 30,
                      message: "El nombre no puede tener más de 30 caracteres",
                    },
                    minLength: {
                      value: 3,
                      message: "El nombre no puede tener menos de 3 caracteres",
                    },
                  }}
                  render={({ field }) => (
                    <input
                      className={style.input}
                      type="text"
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        trigger("name");
                      }}
                    />
                  )}
                />
                <div className={style.errorMenssage}>
                  {errors.name && (
                    <p className={style.errorText}>{errors.name.message}</p>
                  )}
                </div>
              </div>
            </div>
            <div className={style.divCampo}>
              <label className={style.label} htmlFor="surname">
                Apellido completo
              </label>
              <div className={style.divInput}>
                <Controller
                  name="surname"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Este campo es obligatorio",
                    maxLength: {
                      value: 30,
                      message:
                        "El apellido no puede tener más de 30 caracteres",
                    },
                    minLength: {
                      value: 4,
                      message:
                        "El apellido no puede tener menos de 4 caracteres",
                    },
                  }}
                  render={({ field }) => (
                    <input
                      className={style.input}
                      type="text"
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        trigger("surname");
                      }}
                    />
                  )}
                />
                <div className={style.errorMenssage}>
                  {errors.surname && (
                    <p className={style.errorText}>{errors.surname.message}</p>
                  )}
                </div>
              </div>
            </div>
            <div className={style.divCampo}>
              <label className={style.label} htmlFor="email">
                
                Correo electronico
              </label>
              <div className={style.divInput}>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Este campo es obligatorio",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Este no es un correo electrónico válido",
                    },
                    validate: async (value) => {
                      const success = await verifyEmail(value);
                      if (success)
                        return "Este correo ya registrado, intenta con uno nuevo";
                      else return true;
                    },
                  }}
                  render={({ field }) => (
                    <input
                      className={style.input}
                      type="text"
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        trigger("email");
                      }}
                    />
                  )}
                />
                <div className={style.errorMenssage}>
                  {errors.email && (
                    <p className={style.errorText}>{errors.email.message}</p>
                  )}
                </div>
              </div>
            </div>
            <div className={style.divCampo}>
              <label className={style.label} htmlFor="password">
                
                Contraseña
              </label>
              <div className={style.divInput}>
                <div className={style.contraseña}>
                  <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <input
                        className={style.input}
                        type={showPassword ? "text" : "password"}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          trigger("password");
                          validatePassword(e);
                        }}
                      />
                    )}
                  />
                  <span
                    className={style.passwordtoggle}
                    onClick={togglePasswordVisibilityPass}
                  >
                    {showPassword ? (
                      <>
                        <img
                          className={style.img}
                          src="https://api.iconify.design/material-symbols:visibility-off-outline.svg?color=%233cbbed"
                          alt="eyeopne"
                        />
                      </>
                    ) : (
                      <>
                        <img
                          className={style.img}
                          src="https://api.iconify.design/material-symbols:visibility-outline.svg?color=%233cbbed"
                          alt="eyeopne"
                        />
                      </>
                    )}
                  </span>
                </div>
                {validate !== true ? (
                  <div className={style.errorMenssagePass}>
                    {erroresPass.map((error, index) => (
                      <span className={style.errorText} key={index}>
                        {error}
                      </span>
                    ))}
                  </div>
                ) : null}
              </div>
            </div>
            <div className={style.divCampo}>
              <label className={style.label} htmlFor="repeatPassword">
                
                Repetir contraseña
              </label>
              <div className={style.divInput}>
                <div className={style.contraseña}>
                  <Controller
                    name="repeatPassword"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Este campo es obligatorio",
                      validate: passwordMatchRule,
                    }}
                    disabled={isRepeatPasswordEnabled === false}
                    render={({ field }) => (
                      <input
                        className={style.input}
                        type={showPasswordRepeat ? "text" : "password"}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          trigger("repeatPassword");
                        }}
                      />
                    )}
                  />
                
                  <span
                    className={style.passwordtoggle}
                    onClick={togglePasswordVisibilityPassRe}
                  >
                    {showPasswordRepeat ? (
                      <>
                        <img
                          className={style.img}
                          src="https://api.iconify.design/material-symbols:visibility-off-outline.svg?color=%233cbbed"
                          alt="eyeopne"
                        />
                      </>
                    ) : (
                      <>
                        <img
                          className={style.img}
                          src="https://api.iconify.design/material-symbols:visibility-outline.svg?color=%233cbbed"
                          alt="eyeopne"
                        />
                      </>
                    )}
                  </span>
                </div>
                <div className={style.errorMenssage}>
                  {errors.repeatPassword && (
                    <p className={style.errorText}>
                      {errors.repeatPassword.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className={style.divCampo}>
              <label className={style.label} htmlFor="birthDate">
                Fecha de nacimiento:
              </label>
              <div className={style.divInput}>
                <Controller
                  name="birthDate"
                  control={control}
                  defaultValue={currentDate}
                  rules={{
                    required: "Este campo es obligatorio",
                    validate: (value) => {
                      const eighteenYearsAgo = new Date();
                      eighteenYearsAgo.setFullYear(
                        currentDate.getFullYear() - 18
                      );
                      if (isBefore(parseISO(value), eighteenYearsAgo)) {
                        return true;
                      }
                      return "Debes ser mayor de 18 años para registrarte.";
                    },
                  }}
                  render={({ field }) => (
                    <input
                      type="date"
                      className={style.select}
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        trigger("birthDate");
                      }}
                    />
                  )}
                />
                <div className={style.errorMenssage}>
                  {errors.birthDate && (
                    <p className={style.errorText}>
                      {errors.birthDate.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className={style.divCampo}>
              <label className={style.label} htmlFor="gender">
                
                Género
              </label>
              <div className={style.divInput}>
                <Controller
                  name="gender"
                  control={control}
                  defaultValue=""
                  // rules={{ required: 'Seleccione su género' }}
                  render={({ field }) => (
                    <select
                      className={style.select}
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        trigger("gender");
                      }}
                    >
                      <option value="" disabled>
                        
                        Seleccione su género
                      </option>
                      <option value="Masculino"> Masculino </option>
                      <option value="Femenino"> Femenino </option>
                      <option value="Prefiero no decirlo">
                        
                        Prefiero no decirlo
                      </option>
                    </select>
                  )}
                />
                
              </div>
            </div>
            
          </div>
          <button
            type="submit"
            className={style.formbutton}
            disabled={formDisabled}
          >
            Registrarme
          </button>
        </form>
        <div className={style.mensaje}>
          <NavLink to="/login">
            <button className={style.formbuttonBack}>Volver</button>
          </NavLink>
          {confirmRegistro === true ? (
            <>
              <p className={style.positivo}>Usuario registrado con exito</p>
            </>
          ) : confirmRegistro === false ? (
            <>
              <p className={style.negativo}>
                No se pudo registrar, vuelve a intentarlo
              </p>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default RegistroUsuario;
