import style from "./SearchedProduct.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react"; 
import { useParams } from "react-router-dom";
import { getProductByName } from "../../redux/Actions/getProductByName";
import { Spinner } from "../../components/Carga/Carga";
import Footer from "../../components/Footer/Footer";
import CardsArr from "../../components/Cards/CardsArr";
import FiltrosBusq from "./filtrosBusqueda/FiltrosBusq";

const SearchedProduct = () => {
  const { name } = useParams();
  const dispatch = useDispatch();

  const [arr, setArr] = useState([]);
  const [sortBy, setSortBy] = useState("default");
  const [orderBy, setOrderBy] = useState("asc");
  const [loading, setLoading] = useState(true);
  const [showOnlyOffers, setShowOnlyOffers] = useState(false);  // Estado para filtrar por ofertas
  const [offerStatus, setOfferStatus] = useState("all"); // Estado para el estado de oferta (todos, con oferta, sin oferta)

  const productByName = useSelector((state) => state.productByName);

  useEffect(() => {
    setLoading(true);
    dispatch(getProductByName(name))
      .finally(() => setLoading(false));
  }, [name, dispatch]);

  useEffect(() => {
    setArr(productByName);
  }, [productByName]);

  const handleSort = (key) => {
    setSortBy(key);
  };

  const filterProducts = () => {
    let filteredArr = [...arr];
  
    // Filtrar por ofertas si showOnlyOffers es true
    if (showOnlyOffers) {
      filteredArr = filteredArr.filter((product) => product.statusOffer);
    }
  
    // Filtrar por estado de oferta
    if (offerStatus === "offer") {
      filteredArr = filteredArr.filter((product) => product.statusOffer);
    } else if (offerStatus === "notOffer") {
      filteredArr = filteredArr.filter((product) => !product.statusOffer);
    }
  
    // Aplicar orden y dirección de ordenamiento por precio
    filteredArr.sort((a, b) => {
      const priceComparison = a.priceOfList - b.priceOfList;
      return orderBy === "asc" ? priceComparison : -priceComparison;
    });
  
    return filteredArr;
  };

  const filteredProducts = filterProducts();

  return (
    <>
      <div className={style.divSearchedProduct}>
        <div className={style.divContenido}>
          <h1 className={style.h1}>resultados con: {name}</h1>
          <FiltrosBusq
            setOrderBy={setOrderBy}
            setShowOnlyOffers={setShowOnlyOffers}
            setOfferStatus={setOfferStatus}
            setSortBy={setSortBy}  // Agrega esta línea
          />
          {loading ? (
            <div className={style.container}>
              <Spinner />
            </div>
          ) : (
            filteredProducts && filteredProducts.length > 0 ? (
              <CardsArr allProducts={filteredProducts} />
            ) : (
              <div className={style.container}>
                <span className={style.span}>¡No se Encontraron Productos!</span>
              </div>
            )
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SearchedProduct;