import { HISTORIAL } from "../actionTypes";
import axios from "axios";

export const userHistorial = (userId) => {

    return async (dispatch) => {
      const response = await axios.get(`https://api.fixershoes.com/order/${userId}`);

      dispatch({
        type: HISTORIAL,
        payload: response.data.orders,
      });
    };
  
};
