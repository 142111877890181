import { useEffect, useState } from "react";
import { useDispatch, useSelector} from "react-redux"
import style from "./pedidos.module.css"
import { getAllOrders } from "../../../redux/Actions/getAllOrders";
import axios from "axios";


const Pedidos = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAllOrders());
  }, []);

  const allOrders = useSelector((state) => (state.allOrders))
   
    const [orders, setOrders] = useState([])
    const [origen, setOrigen] = useState("")
    const [mensaje, setMensaje] = useState("")
    const [idOrden, setIdOrden] = useState("")
    const [isDetail, setIsDetail] = useState(false)
    const [detailOrder, setDetailOrder] = useState({})
    const [statusModify, setStatusModify] = useState("")
    const [nextStatus, setNextStatus] = useState("")
    const [backStatus, setBackStatus] = useState("")

    useEffect(()=> {
      if(statusModify !== "") {
        if (statusModify === "Pendiente") {
          setNextStatus("Enviado")
          setBackStatus("")
        }
        if (statusModify === "Enviado") {
          setNextStatus("Entregado")
          setBackStatus("Pendiente")
        }
        if (statusModify === "Entregado") {
          setNextStatus("")
          setBackStatus("Enviado")
        }
      }
    }, [statusModify])


    const handleDetail = async (id) => {
      if (allOrders?.length === 0) {
        const orden = orders?.filter((or) => or.id === id)
        setDetailOrder(orden[0])
        if (isDetail) {
          setIsDetail(false)
          setDetailOrder({})
          setStatusModify("")
        }
        else {
          setIsDetail(true)
          setDetailOrder(orden[0])
          setStatusModify(orden[0].status)
          setIdOrden(orden[0].id)
        }
      }
      else if (allOrders?.length > 0) {
        if (isDetail) {
          setIsDetail(false)
          setDetailOrder({})
          setStatusModify("")
          setOrigen("")
        }
        else {
          const endpoint = `https://api.fixershoes.com/order/id/${id}`
          const response = await axios.get(endpoint)
          console.log(response);
          const orden = response.data
          const {order} = orden 
          setIsDetail(true)
          setDetailOrder(order)
          setStatusModify(order.status)
          setIdOrden(order.id)
          setOrigen("DB")
        }
      }
    }
    const updateState = async (e) => {
      const status = e.target.value
      const id = idOrden
      if (origen === "DB") {
        try {
          const endpoint = "https://api.fixershoes.com/order/update"
          const body = {
            status: status,
            id: id
          }
          const response = await axios.patch(endpoint, body)
          const {data} = response
          setMensaje(data)
          dispatch(getAllOrders())
        }
        catch (error) {
          setMensaje("Hubo un error, vuelve a intentarlo")
        }
      }
    }



    return (
        <div className={style.contenedor}>
            <div className={style.divComplementario}>
                <h5 className={style.tituloPedidos}>Pedidos</h5>
            </div>
            {isDetail === false ? 
            (<div className={style.divTabla}>
              <table className={style.table}>
                  <tr className={style.tr}> 
                      <th className={style.th}>ID</th>
                      <th className={style.th}>ID Mercado Pago</th>
                      <th className={style.th}>Cliente</th>
                      <th className={style.th}>Estado</th>
                      <th className={style.th}>Fecha</th>
                      <th className={style.th}>Valor total</th>
                      <th className={style.th}>Medio de pago</th>
                      <th className={style.th}>Detalle</th>
                  </tr>
                  {Array.isArray(allOrders)? allOrders.map((orden) => (
                  <tr className={style.tr} key={orden.id}>
                      <td className={style.td}>{orden.id}</td>
                      <td className={style.td}>{orden.idMp === 'null' ? '---' : orden.idMp}</td>
                      <td className={style.td}>{orden.name + " " + orden.surname}</td>
                      <td className={style.td}>{orden.status}</td>
                      <td className={style.td}>{new Date(orden.createdAt).toLocaleString('es-CO', {year: 'numeric',month: 'numeric',day: 'numeric',hour: 'numeric',minute: 'numeric',hour12: false})}</td>
                      <td className={style.td}>${orden.totalAmount}</td>
                      <td className={style.td}>
                        {orden.payment === 'credit_card' ? 'Tarjeta de Crédito/Débito' : 
                          orden.payment === 'ticket' ? 'Efecty' : 
                            orden.payment === 'account_money' ? 'Cuenta MercadoPago' :
                            orden.payment === 'null' ? 'Compra no realizada' : ''}
                      </td>
                      <td className={style.tdetail}>
                      <button className={style.botonEdit} onClick= {() => handleDetail (orden.id)}>Detalle</button>
                      </td>
                      </tr>
                      )):(
                        null
                      )
                    }
              </table>
              </div>): ( 
                <div>
                    <div className={style.divtitulo}>
                        <button onClick= {handleDetail} className={style.backPedBoton}> &#8678; </button>
                        <h4 className={style.ordenTitulo}>Orden # {detailOrder.id}</h4>
                    </div>
                    <div className={style.divInformacion}>
                      <h5 className={style.tituloSeccion}>Detalles de la orden</h5>
                      <p className={style.mensajeProductos}><strong>Fecha de creación: </strong>{new Date(detailOrder.createdAt).toLocaleString('es-CO', {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false})}</p>
                      <p className={style.mensajeProductos}><strong>Valor total: </strong> ${detailOrder.totalAmount}</p>
                      <p className={style.mensajeProductos}><strong>Pago: </strong> {detailOrder.payStatus === 'accredited' ? 'Aprobado' : 
                          detailOrder.payStatus === 'pending_waiting_payment' ? 'Pendiente'
                          : 'Rechazado'}</p>
                      <p className={style.mensajeProductos}><strong>Método de pago:</strong>{detailOrder.payment === 'credit_card' ? 'Tarjeta de Crédito/Débito' : 
                          detailOrder.payment === 'ticket' ? 'Efecty' : 
                            detailOrder.payment === 'account_money' ? 'Cuenta MercadoPago' : 
                            detailOrder.payment === 'null' ? 'Compra no realizada' : ''}</p>
                      <p className={style.mensajeProductos}><strong>Estado de la orden: </strong>{detailOrder.status}</p>
                      {nextStatus !== "" ? (nextStatus === "Enviado" ? (<button value="Enviado" onClick={(e)=> {updateState(e); setTimeout(()=> {setIsDetail(false)}, 500)}} className={style.modifyOrdenNext}>Cambiar a Enviado</button>):(<button onClick={(e)=> {updateState(e); setTimeout(()=> {setIsDetail(false)}, 500)}} value="Entregado" className={style.modifyOrdenNext}>Cambiar a Entregado</button>)):(null)}
                      {backStatus !== "" ? (backStatus === "Pendiente" ? (<button onClick={(e)=> {updateState(e); setTimeout(()=> {setIsDetail(false)}, 500)}} value="Pendiente" className={style.modifyOrdenBack}>Cambiar a Pendiente</button>):(<button onClick={(e)=> {updateState(e); setTimeout(()=> {setIsDetail(false)}, 500)}} value="Enviado" className={style.modifyOrdenBack}>Cambiar a Enviado</button>)):(null)}
                      
                    </div>
                    <div>
                        <h5 className={style.tituloSeccion}>Productos de la orden</h5>
                        <div className={style.divProductos}>
                          {detailOrder?.Products && detailOrder?.Products.length > 0 ? (
                            detailOrder?.Products.map((product) => {
                              return (
                              <div className={style.divProduct} key={product.id}>
                                <div className={style.divProductImage}>
                                <img className={style.productImage}src={product.firstImage} alt="" />
                                </div>
                                <p className={style.mensajeProductosLittle}> <strong>{product.name}</strong></p>
                                <p className={style.mensajeProductosLittle}> <strong>Cantidad:</strong> {product.OrderItems.quantity} unidades</p>
                                <p className={style.mensajeProductosLittle}> <strong>Precio unitario: </strong>$ {product.OrderItems.price} </p>
                                <p className={style.mensajeProductosLittle}> <strong>Subtotal producto: </strong> $ {product.OrderItems.price * product.OrderItems.quantity}</p>
                              </div>
                              );
                            })
                              ) : (
                                null
                              )}
                          </div>
                    </div>
                    <div className={style.divInformacion}>
                        <h5 className={style.tituloSeccion}>Información del cliente</h5>
                        <p className={style.mensajeProductos}><strong>Nombre:</strong> {detailOrder.name}</p>
                        <p className={style.mensajeProductos}><strong>Apellido:</strong> {detailOrder.surname}</p>
                        <p className={style.mensajeProductos}><strong>Numero de telefono:</strong> {detailOrder.phone}</p>
                        <p className={style.mensajeProductos}><strong>CC:</strong> {detailOrder.cc}</p>
                    </div>

                    <div className={style.divInformacion}>
                        <h5 className={style.tituloSeccion}>Información del envio </h5>
                        {detailOrder?.retiro === "" ? (
                          <div>
                            <p className={style.mensajeProductos}>Pais: Colombia</p>
                            <p className={style.mensajeProductos}>Departamento: {detailOrder.department}</p>
                            <p className={style.mensajeProductos}>Ciudad: {detailOrder.city}</p>
                            <p className={style.mensajeProductos}>Dirección: {detailOrder.address}</p>
                            <p className={style.mensajeProductos}>Numero de contacto: {detailOrder.phone}</p>
                            <p className={style.mensajeProductos}>Nombre: {detailOrder.name + " " + detailOrder.surname}</p>
                          </div>
                        ):(
                            <div>
                              <p className={style.mensajeProductos}><strong>Retiro en </strong> {detailOrder.retiro} por parte del cliente</p>
                            </div>
                        )}
                    </div>
                </div>
              )}
        </div>
    )
}
export default Pedidos


