import React from 'react';
import YouTube from 'react-youtube';
import style from './DetailPage.module.css'

const VideoYt = ({ videoId }) => {
    
    const videoOptions = {
        width: '100%',
        height: '100%'
      };

  return (

        <YouTube
        videoId={videoId}
        className={style.video}
        opts={videoOptions}
        width= '100%'
        height= '100%'
        />

  );
};

export default VideoYt;