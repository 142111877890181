import React from "react";
import style from './FiltrosBusq.module.css'

const FiltrosBusq = ({ setOrderBy, setShowOnlyOffers, setOfferStatus, setSortBy }) => {

  const handleOrderByChange = (e) => {
    setOrderBy(e.target.value);
    setSortBy('price'); // Agrega esta línea para indicar que estás ordenando por precio
  };

  const handleShowOnlyOffersChange = (e) => {
    setShowOnlyOffers(e.target.value === "offer");
    setOfferStatus(e.target.value);
  };

  return (
    <div className={style.divPrinp}>
      <label className={style.label}>
        Ordenar por Precio:
        <select className={style.select} onChange={handleOrderByChange}>
          <option value="asc">Menor a Mayor</option>
          <option value="desc">Mayor a Menor</option>
        </select>
      </label>
      <label className={style.label}>
        Estado de Oferta:
        <select className={style.select} onChange={handleShowOnlyOffersChange}>
          <option value="all">Todos</option>
          <option value="offer">Con Oferta</option>
          <option value="notOffer">Sin Oferta</option>
        </select>
      </label>
    </div>
  );
};

export default FiltrosBusq;
