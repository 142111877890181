import BarDashboard from "../../components/BarDashboard/BarDashboard"


const Dashboard = () => {
    return (
        <>
       <BarDashboard/>       
        </>
    )
}

export default Dashboard