import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import style from './Banner.module.css';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { getBanner } from '../../redux/Actions/getBanner';
import { deleteBanner } from '../../redux/Actions/deleteBanner';

export default function Banner() {

  const dispatch = useDispatch()
  const banners = useSelector(state => state.banner)
  const adminProfile = useSelector((state) => state.adminProfile);


  const [images, setImages] = useState([]);
  const [newImage, setNewImage] = useState({ src: '', link: '' });
  const [num, setNum]= useState('')

  const [isAdmin, setIsAdmin] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalDelete, setIsModalDelete] = useState(false)


  useEffect(() => {
    dispatch(getBanner());
  }, []);
  
  useEffect(() => {
    setIsAdmin(adminProfile);
    setImages(banners);
  
    console.log("Images after fetching from database:", images);
  }, [isAdmin, banners]);

  

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);  
  const openDelete = () => setIsModalDelete(true);
  const closeDelete = () => setIsModalDelete(false);


    const handleUpload = async (e) => {
      
      const file = newImage.src;

      const formData = new FormData();

      if (file) {
        formData.append("file", file);
        formData.append("upload_preset", "to62brlp");
      }
      console.log('pase por aca')
        try {
          const res = await axios.post("https://api.cloudinary.com/v1_1/dgxp4c4yk/image/upload", formData);

          const secureUrl = res.data.secure_url;
          console.log('pase por aca2')

          setNewImage({ ...newImage, src: secureUrl });


          await axios.post("https://api.fixershoes.com/banner/create",{imageUrl:secureUrl,link: newImage.link})
          console.log('pase por aca33')
          }
          catch (error) {
            console.log('pase por aca0000')
            console.error("Error en la solicitud:", error);
          
          }
          console.log('pase por aca4444')
      closeModal();
      window.location.reload()
    };

    const handlerNum = (e) => {
      setNum(e.target.value)
    }

    const handleDelete = () => {
      dispatch(deleteBanner(num));
      closeDelete();
      window.location.reload()
    };


  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    arrows: false,
  };

  return (
    <>
    <div className={style.banner}>
      <Slider {...settings}>
        
        {images?.map((image, index) => (
          <div key={image.id} className={style.slide}>
          <a href={image.link}>
            <img
              src={image.imageUrl}
              alt={`Imagen ${index + 1}`}
              className={style.image}
            />
          </a>
        </div>
        ))}
      </Slider>
    </div>
      {isAdmin && (
        <>
        <div className={style.conteinerButton}>
          <div className={style.divButton}>
            <button className={style.button} onClick={openModal}>
              Subir Imagen
            </button>
          </div> 
          <div className={style.divButton}>
            <button className={style.button} onClick={openDelete}>
              Eliminar Imagen
            </button>
          </div>

        </div>
        
        </>
      )}

      {isModalOpen && (
        <div className={style.modalOverlay}>
          <div className={style.modalContent}>
            <span>Cargar Nueva Imagen</span>
            <input 
            type="file" 
            onChange={(e) => setNewImage({ ...newImage, src: e.target.files[0] })} />
            <input
              type="text"
              placeholder="Enlace"
              value={newImage.link}
              onChange={(e) => setNewImage({ ...newImage, link: e.target.value })}
            />
            <button onClick={handleUpload}>Cargar</button>
            <button onClick={closeModal}>Cancelar</button>
          </div>
        </div>
      )}

      {isModalDelete && (
        <div className={style.modalOverlay}>
          <div className={style.modalContent}>
            
            <div className={style.divImgDel}>
              {images?.map((image, index) => (
              <div key={image.id} className={style.divDel}>
              <span>{image.id}</span>
              <img
                src={image.imageUrl}
                alt={`Imagen ${index + 1}`}
                className={style.imageP}
              />
            
              </div>
              ))
              }
              
            </div>
            <span>Ingrese el numero de Imagen que quiere eliminar</span>
            <input 
            type="number"
            value={num}
            onChange={handlerNum}
             />
            
            <button onClick={handleDelete}>Enviar</button>
            <button onClick={closeDelete}>Cancelar</button>
          </div>
        </div>
      )}

    </>
  );
}
