import { FAVORITOS } from "../actionTypes";
import axios from "axios";

export const userFavoritos = (UserId) => {


    return async (dispatch) => {
      const response = await axios.get(
        `https://api.fixershoes.com/favorites/${UserId}`
      );
      dispatch({
        type: FAVORITOS,
        payload: response.data,
      });
    };

};
