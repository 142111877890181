import { SET_ORDER2 } from "../actionTypes";

export const setOrder2 = (order) => {
  return {
    type: SET_ORDER2,
    payload: order
  };
};


