import { DELETE_BANNER } from "../actionTypes";
import axios from "axios";

export const deleteBanner= (id) => {
  return async function (dispatch) {
    try {
        await axios.delete(`https://api.fixershoes.com/banner/delete/${id}`);

        dispatch({ 
            type: DELETE_BANNER, 
            payload: id
        });
        
    } catch (error) {
      return "Hubo un error al traer los banners: " + error.message;
    }
  };
};