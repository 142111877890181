import { SEND_REVIEW } from "../actionTypes";
import axios from "axios";

export const sendReview = (review) => {

    return async (dispatch) => {
      const response = await axios.post(
        "https://api.fixershoes.com/users/reviews",
        review
      );
      dispatch({
        type: SEND_REVIEW,
        payload: response.data,
      });
    };

};
