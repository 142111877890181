import React from 'react';
import style from './Preguntas.module.css'

const Preguntas = () => {
  return (
    <>
      <section className={style.accordion}>
        <details className={style.accordionItem} name='info'>
          <summary className={style.accordionSummary}>¿Se puede teñir el calzado de ante?</summary>
          <p className={style.accordionContent}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum amet perferendis, aliquid quas, nesciunt delectus, voluptas officiis voluptatem mollitia aperiam esse.
          </p>
        </details>
        <details className={style.accordionItem} name='info'>
          <summary className={style.accordionSummary}>¿Zapatos pequeños o estrechos? ¿Es posible una solución?</summary>
          <p className={style.accordionContent}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum amet perferendis, aliquid quas, nesciunt delectus, voluptas officiis voluptatem mollitia aperiam esse.
          </p>
        </details>
        <details className={style.accordionItem} name='info'>
          <summary className={style.accordionSummary}>¿Cómo se pueden limpiar manchas de aceite, que producto recomienda?</summary>
          <p className={style.accordionContent}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum amet perferendis, aliquid quas, nesciunt delectus, voluptas officiis voluptatem mollitia aperiam esse.
          </p>
        </details>
        <details className={style.accordionItem} name='info'>
          <summary className={style.accordionSummary}>¿Tiene un kit especial de limpieza para tenis?</summary>
          <p className={style.accordionContent}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum amet perferendis, aliquid quas, nesciunt delectus, voluptas officiis voluptatem mollitia aperiam esse.
          </p>
        </details>
        <details className={style.accordionItem} name='info'>
          <summary className={style.accordionSummary}>¿el shampoo me sirve para cualquier tipo de material?</summary>
          <p className={style.accordionContent}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum amet perferendis, aliquid quas, nesciunt delectus, voluptas officiis voluptatem mollitia aperiam esse.
          </p>
        </details>
        <details className={style.accordionItem} name='info'>
          <summary className={style.accordionSummary}>¿Con que puedo revivir el color de mis zapatos de cuero?</summary>
          <p className={style.accordionContent}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum amet perferendis, aliquid quas, nesciunt delectus, voluptas officiis voluptatem mollitia aperiam esse.
          </p>
        </details>
        <details className={style.accordionItem} name='info'>
          <summary className={style.accordionSummary}>¿Cuál es el tinte más recomendado para el cuero?</summary>
          <p className={style.accordionContent}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum amet perferendis, aliquid quas, nesciunt delectus, voluptas officiis voluptatem mollitia aperiam esse.
          </p>
        </details>
        <details className={style.accordionItem} name='info'>
          <summary className={style.accordionSummary}>¿Cómo quito las arrugas de mis tenis de cuero?</summary>
          <p className={style.accordionContent}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum amet perferendis, aliquid quas, nesciunt delectus, voluptas officiis voluptatem mollitia aperiam esse.
          </p>
        </details>
        <details className={style.accordionItem} name='info'>
          <summary className={style.accordionSummary}>¿Cómo aclaro la suela de mis zapatos que era blanca ahora esta manchada?</summary>
          <p className={style.accordionContent}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum amet perferendis, aliquid quas, nesciunt delectus, voluptas officiis voluptatem mollitia aperiam esse.
          </p>
        </details>
        
      </section>
    </>
  );
}

export default Preguntas;
