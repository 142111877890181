import { USER_PROFILE } from "../actionTypes";
import axios from "axios";

export const loginUser = (userData) => {

    return async (dispatch) => {
      const response = await axios.post(
        "https://api.fixershoes.com/auth/login",
        userData
      );
      dispatch({
        type: USER_PROFILE,
        payload: response.data,
      });
    };

};
