import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from "./Pay.module.css";

const Pending = () => {
  const dataProfile = useSelector((state) => state.dataProfile);
  const [secondsRemaining, setSecondsRemaining] = useState(5);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const timer = setInterval(() => {
      if (secondsRemaining > 0) {
        setSecondsRemaining((prevSeconds) => prevSeconds - 1);
      }
    }, 1000);

    // Redirige al usuario a localhost:3000/ después de 5 segundos
    const redirectTimer = setTimeout(() => {
      navigate("/");
    }, 5000);

    // Limpia los temporizadores cuando el componente se desmonta
    return () => {
      clearInterval(timer);
      clearTimeout(redirectTimer);
    };
  }, [navigate, secondsRemaining]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (dataProfile === null) {
        window.location.href = "/";
      }
    }, 10);

    return () => clearTimeout(timeoutId);
  }, [dataProfile]);

  return (
    <div className={styles.containerV}>
      <h1 className={styles.h1V}>Pago Pendiente</h1>
      <p className={styles.pV}>Envía tu comprobante de pago a nuestro WhatsApp</p>
      <p className={styles.redirectV}>Redireccionando en <span className={styles.secondsRemainingR}>{secondsRemaining}</span> segundos...</p>
    </div>
  );
};

export default Pending;
