import { USER_CHANGE } from "../actionTypes";
import axios from "axios";

export const modifyData = (userData) => {

    return async (dispatch) => {
      const response = await axios.patch(
        `https://api.fixershoes.com/users/${userData.id}`,
        userData
      );
      dispatch({
        type: USER_CHANGE,
        payload: response.data,
      });
    };

};